import axiosInstance from './axios';
import HttpApi from './endpoints';
/**
 * Fetches the list of available languages.
 *
 * @returns A promise that resolves to an ApiResponse containing an array of LanguageItemEntity.
 */
export const getLanguageList = () => {
    return axiosInstance.get(HttpApi.getLanguageList);
};
