export function getAvatarColor(nickname) {
    const colors = [
        '#3370ff',
        '#14c9c9',
        '#165dff',
        '#ff7d00',
        '#eb0aa4',
        '#7816ff',
        '#00b42a',
    ];
    const hash = nickname.split('').reduce((acc, char) => {
        return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    return colors[Math.abs(hash) % colors.length] || '#3370ff';
}
export class ColorManager {
    colorMap = new Map();
    colors = [
        '#3370ff',
        '#14c9c9',
        '#165dff',
        '#ff7d00',
        '#eb0aa4',
        '#7816ff',
        '#00b42a',
    ];
    colorIndex = 0;
    getColor(speaker) {
        const normalizedSpeaker = this.normalizeSpeaker(speaker);
        if (!this.colorMap.has(normalizedSpeaker)) {
            const color = this.colors[this.colorIndex];
            this.colorMap.set(normalizedSpeaker, color);
            this.colorIndex = (this.colorIndex + 1) % this.colors.length;
        }
        return this.colorMap.get(normalizedSpeaker);
    }
    updateColor(oldName, newName, applyToAll) {
        const oldNormalized = this.normalizeSpeaker(oldName);
        const newNormalized = this.normalizeSpeaker(newName);
        if (oldNormalized === newNormalized) {
            return this.colorMap.get(oldNormalized) || this.getColor(newNormalized);
        }
        if (applyToAll) {
            const color = this.colorMap.get(oldNormalized);
            if (color) {
                this.colorMap.delete(oldNormalized);
                this.colorMap.set(newNormalized, color);
            }
            return color || this.getColor(newNormalized);
        }
        else {
            return this.getColor(newNormalized);
        }
    }
    normalizeSpeaker(speaker) {
        return speaker.toLowerCase().replace(/\s+/g, '');
    }
    reset() {
        this.colorMap.clear();
        this.colorIndex = 0;
    }
}
