import { defineStore } from 'pinia';
import { getFeedbackDetail } from '@/api/feedback';
export const useFeedbackDetailStore = defineStore('feedbackDetail', {
    state: () => ({
        id: undefined,
        userUid: undefined,
        issueDesc: undefined,
        contactInfo: undefined,
        feedbackDate: undefined,
        imageNum: undefined,
        status: undefined,
        statusDesc: undefined,
        suggestions: undefined,
        dealDate: undefined,
        imageUrlList: undefined,
        createTime: undefined,
        updateTime: undefined,
    }),
    actions: {
        setFeedbackDetail(payload) {
            Object.assign(this, payload);
        },
        async fetchFeedbackDetail(id) {
            try {
                const response = await getFeedbackDetail(id);
                this.setFeedbackDetail(response.data);
            }
            catch (error) {
                console.error('Error fetching feedback details:', error);
                throw error;
            }
        },
        resetFeedbackDetail() {
            this.$reset();
        },
    },
});
