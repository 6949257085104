import { defineStore } from 'pinia';
import { updateMailNotificationSwitch } from '@/api/auth';
export const useUserStore = defineStore('user', {
    state: () => ({
        user: null,
    }),
    actions: {
        setUser(user) {
            this.user = user;
        },
        async fetchUserInfo() {
            // Implement this method to fetch user info from your API
            // For now, we'll leave it as a placeholder
            console.log('Fetching user info...');
        },
        async updateUserSettings(settings) {
            try {
                await updateMailNotificationSwitch(settings.transcribeFlag ?? false, settings.translateFlag ?? false);
                if (this.user) {
                    this.user = { ...this.user, ...settings };
                }
            }
            catch (error) {
                console.error('Failed to update user settings:', error);
                throw error;
            }
        },
    },
});
