export function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}
// 新增 getExpireNotice 函数
export function getExpireNotice(expireTimestamp) {
    const now = Date.now();
    const expiredTime = new Date(expireTimestamp);
    const diffInMilliseconds = expiredTime.getTime() - now;
    if (diffInMilliseconds < 0) {
        return '已过期';
    }
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    if (diffInDays >= 1) {
        return `${diffInDays}天后删除`;
    }
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    if (diffInHours >= 1) {
        return `${diffInHours}小时后删除`;
    }
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    if (diffInMinutes > 0) {
        return `${diffInMinutes}分钟后删除`;
    }
    return '即将删除';
}
export function formatExpireTime(expireTimestamp) {
    const now = Date.now();
    const remainingTime = expireTimestamp - now;
    if (remainingTime <= 0)
        return '已过期';
    const days = Math.floor(remainingTime / (24 * 60 * 60 * 1000));
    if (days > 0) {
        return `${days} 天`;
    }
    const hours = Math.floor(remainingTime / (60 * 60 * 1000));
    if (hours > 0) {
        return `${hours} 小时`;
    }
    const minutes = Math.max(1, Math.floor(remainingTime / (60 * 1000)));
    return `${minutes} 分钟`;
}
