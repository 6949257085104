import { defineComponent, computed, ref, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { IconMenu, IconCheck, } from '@arco-design/web-vue/es/icon';
import { useAuthStore } from '@/stores/authStore'; // Import the auth store
export default defineComponent({
    name: 'Header',
    components: {
        // IconDown,
        IconMenu,
        // IconRight,
        IconCheck,
    },
    setup() {
        const route = useRoute();
        const { t, locale } = useI18n();
        const router = useRouter();
        const isMobileView = ref(false);
        const mobileMenuVisible = ref(false);
        const isMobileWeb = () => {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        };
        const checkMobileView = () => {
            isMobileView.value = isMobileWeb() || window.innerWidth <= 768;
        };
        onMounted(() => {
            checkMobileView();
            window.addEventListener('resize', checkMobileView);
        });
        onUnmounted(() => {
            window.removeEventListener('resize', checkMobileView);
        });
        const currentRoute = computed(() => route.name);
        const currentLanguage = computed(() => {
            switch (locale.value) {
                case 'zh':
                    return '简体中文';
                case 'en':
                    return 'English';
                case 'ja':
                    return '日本語';
                default:
                    return '简体中文';
            }
        });
        const changeLanguage = (value) => {
            locale.value = value;
        };
        const goToLogin = () => {
            // 如果是移动视图，始终跳转到 Flutter Web 应用
            if (isMobileView.value) {
                window.location.href = '/web/';
            }
            else {
                // 桌面视图保持原有的路由跳转
                router.push('/login');
            }
        };
        const toggleMobileMenu = () => {
            mobileMenuVisible.value = !mobileMenuVisible.value;
        };
        const closeMobileMenu = () => {
            mobileMenuVisible.value = false;
        };
        const languageBottomSheetVisible = ref(false);
        const selectedLanguage = ref(locale.value);
        const languages = [
            { code: 'zh-CN', name: '中文（简体）' },
            { code: 'zh-TW', name: '中文（繁体）' },
            { code: 'en', name: 'English' },
            { code: 'ja', name: '日本語' },
            { code: 'ko', name: '한국어' },
            // 添加更多语言选项...
        ];
        const openLanguageBottomSheet = () => {
            languageBottomSheetVisible.value = true;
            selectedLanguage.value = locale.value;
        };
        const closeLanguageBottomSheet = () => {
            languageBottomSheetVisible.value = false;
        };
        const selectLanguage = (langCode) => {
            selectedLanguage.value = langCode;
        };
        const confirmLanguageSelection = () => {
            locale.value = selectedLanguage.value;
            closeLanguageBottomSheet();
        };
        const navigateAndClose = (path) => {
            router.push(path);
            closeMobileMenu();
        };
        const navigateToHome = () => {
            router.push('/');
        };
        const authStore = useAuthStore(); // Use the auth store
        // Use computed properties to reactively get the login status and user info
        const isLoggedIn = computed(() => authStore.isLoggedIn);
        const userNickname = computed(() => authStore.user?.nickName || '');
        const userInitial = computed(() => userNickname.value.charAt(0).toUpperCase());
        const avatarColor = computed(() => fixedAvatarColor);
        const goToDashboard = () => {
            router.push('/main/dashboard'); // Adjust this path if your dashboard route is different
        };
        // 添加移动端登录状态判断
        const isMobileLoggedIn = computed(() => {
            const loginType = localStorage.getItem('flutter.loginType');
            const authToken = localStorage.getItem('auth_token');
            const nickName = localStorage.getItem('flutter.nickName');
            return loginType === '1' && authToken && nickName;
        });
        // 获取移动端用户昵称，去掉引号
        const mobileUserNickname = computed(() => {
            const nickName = localStorage.getItem('flutter.nickName') || '';
            // 去掉首尾的引号
            return nickName.replace(/^"|"$/g, '');
        });
        // 获取移动端用户首字母，使用处理后的昵称
        const mobileUserInitial = computed(() => mobileUserNickname.value.charAt(0).toUpperCase());
        // 获取移动端用户头像颜色，使用处理后的昵称
        const mobileAvatarColor = computed(() => fixedAvatarColor);
        // 固定的头像背景色
        const fixedAvatarColor = 'rgb(111, 72, 242)';
        return {
            currentRoute,
            currentLanguage,
            changeLanguage,
            goToLogin,
            mobileMenuVisible,
            toggleMobileMenu,
            closeMobileMenu,
            languageBottomSheetVisible,
            selectedLanguage,
            languages,
            openLanguageBottomSheet,
            closeLanguageBottomSheet,
            selectLanguage,
            confirmLanguageSelection,
            isMobileView,
            navigateAndClose,
            navigateToHome,
            isLoggedIn,
            userNickname,
            userInitial,
            avatarColor,
            goToDashboard,
            isMobileLoggedIn,
            mobileUserNickname,
            mobileAvatarColor,
            mobileUserInitial,
            fixedAvatarColor,
        };
    },
});
