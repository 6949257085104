import { defineStore } from 'pinia';
import { getTranscribedFilesByCode, uploadFile, fileTranslateByCode, renameFolderOrFile, deleteFolderOrFile, moveFolderOrFile, } from '@/api/file_folder';
import { Message } from '@arco-design/web-vue';
export const useFileDetailStore = defineStore('fileDetail', {
    state: () => ({
        fileName: '',
        status: '',
        statusText: '',
        duration: 0,
        createTime: 0,
        expiredTime: 0,
        previewUrl: '',
        transcriptSegments: [],
        code: '',
        translatedFileCode: '',
    }),
    getters: {
        uniqueSpeakers: (state) => [
            ...new Set(state.transcriptSegments.map((segment) => segment.speaker)),
        ],
    },
    actions: {
        setFileDetail(payload) {
            Object.assign(this, payload);
        },
        async fetchFileDetail(params) {
            this.setFileDetail({
                fileName: params.name,
                status: params.status,
                statusText: params.status === 'processing' ? '处理中' : '已完成',
                duration: Number(params.duration) || 0,
                createTime: Number(params.createTime) || 0,
                expiredTime: Number(params.expiredTime) || 0,
                previewUrl: params.previewUrl,
                code: params.code,
                translatedFileCode: params.translatedFileCode,
            });
            try {
                const response = await getTranscribedFilesByCode(params.translatedFileCode);
                if (response.data && Array.isArray(response.data.transcriptions)) {
                    this.transcriptSegments = response.data.transcriptions;
                }
                else {
                    throw new Error('Unexpected response data format');
                }
            }
            catch (error) {
                console.error('Error fetching file details:', error);
                throw error;
            }
        },
        async saveFileEdits(updatedTranscriptions) {
            try {
                const updatedEntity = {
                    transcriptions: updatedTranscriptions,
                };
                await uploadFile(updatedEntity, this.translatedFileCode);
                this.transcriptSegments = updatedTranscriptions;
                Message.success('保存成功');
            }
            catch (error) {
                console.error('Error saving edits:', error);
                Message.error('保存失败');
                throw error;
            }
        },
        resetFileDetail() {
            this.$reset(); // 这会将 state 重置为初始值
        },
        async retryTranscription() {
            try {
                await fileTranslateByCode(this.code);
                this.status = 'translating';
                this.statusText = '转录中';
            }
            catch (error) {
                console.error('重新转录失败:', error);
                throw error;
            }
        },
        async renameFile(newName) {
            try {
                await renameFolderOrFile('file', this.code, newName);
                this.fileName = newName;
            }
            catch (error) {
                console.error('重命名文件失败:', error);
                throw error;
            }
        },
        async deleteFile() {
            try {
                await deleteFolderOrFile('file', this.code);
            }
            catch (error) {
                console.error('删除文件失败:', error);
                throw error;
            }
        },
        async moveFile(targetFolderCode) {
            try {
                await moveFolderOrFile('file', this.code, targetFolderCode);
            }
            catch (error) {
                console.error('移动文件失败:', error);
                throw error;
            }
        },
    },
});
