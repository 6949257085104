import axiosInstance from './axios';
import HttpApi from './endpoints';
import axios from 'axios';
import { baseURL } from './axios';
/**
 * Fetches transcribed files by code.
 *
 * @param code - The code of the file to fetch.
 * @returns A promise that resolves to an ApiResponse containing the file data.
 */
export const getTranscribedFilesByCode = async (code) => {
    try {
        const response = await axios.get(`${baseURL}${HttpApi.fileDownload.replace('%s', code)}`, {
            responseType: 'json',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                'Content-Type': 'application/json',
            },
        });
        console.log('API Response:', response);
        if (response.data && Array.isArray(response.data.transcription)) {
            const transcribedEntity = {
                transcriptions: response.data.transcription.map((item) => ({
                    ...item,
                    speaker: item.speaker || 'UNKNOWN_SPEAKER',
                })),
            };
            return {
                code: 200,
                msg: 'Success',
                data: transcribedEntity,
            };
        }
        else {
            console.error('Unexpected response format:', response.data);
            throw new Error('Unexpected response format');
        }
    }
    catch (error) {
        console.error('Error fetching transcribed file:', error);
        if (axios.isAxiosError(error)) {
            console.error('Axios error:', error.response?.data);
        }
        else if (error instanceof Error) {
            console.error('Error message:', error.message);
        }
        else {
            console.error('Unknown error:', error);
        }
        throw error;
    }
};
/**
 * Uploads a modified transcribed file.
 *
 * @param entity - The transcribed entity to upload.
 * @param code - The code of the file to update.
 * @returns A promise that resolves to an ApiResponse.
 */
export const uploadFile = (entity, code) => {
    const formData = new FormData();
    formData.append('code', code);
    formData.append('file', new Blob([JSON.stringify(entity)], { type: 'application/json' }), 'data.json');
    return axiosInstance.post(HttpApi.fileReupload, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
    });
};
/**
 * Deletes a folder or file.
 *
 * @param type - The type of item to delete ('folder' or 'file').
 * @param code - The code of the item to delete.
 * @returns A promise that resolves to an ApiResponse.
 */
export const deleteFolderOrFile = (type, code) => {
    const url = type === 'folder' ? HttpApi.deleteFolder : HttpApi.deleteFile;
    return axiosInstance.delete(url.replace('%s', code));
};
/**
 * Fetches the folder tree structure.
 *
 * @param folderCode - The code of the folder to fetch the tree for.
 * @returns A promise that resolves to an ApiResponse containing the folder tree.
 */
export const getAllFoldersTree = (folderCode) => {
    return axiosInstance.post(HttpApi.getAllFoldersTree, {
        code: folderCode,
        isHide: folderCode !== '',
    });
};
/**
 * Moves a folder or file.
 *
 * @param type - The type of item to move ('folder' or 'file').
 * @param code - The code of the item to move.
 * @param desCode - The destination code.
 * @returns A promise that resolves to an ApiResponse.
 */
export const moveFolderOrFile = (type, code, desCode) => {
    return axiosInstance.post(HttpApi.moveFolderOrFile, { type, code, desCode });
};
/**
 * Renames a folder or file.
 *
 * @param type - The type of item to rename ('folder' or 'file').
 * @param code - The code of the item to rename.
 * @param newName - The new name for the item.
 * @returns A promise that resolves to an ApiResponse.
 */
export const renameFolderOrFile = (type, code, newName) => {
    return axiosInstance.post(HttpApi.renameFolderOrFile, { type, code, newName });
};
/**
 * Initiates file translation by code.
 *
 * @param fileCode - The code of the file to translate.
 * @param language - The target language for translation.
 * @param hasSpeaker - Indicates whether the file has speaker information.
 * @returns A promise that resolves to an ApiResponse.
 */
export const fileTranslateByCode = (fileCode, language = 'Auto Detection', hasSpeaker = true) => {
    return axiosInstance.post(HttpApi.fileTranscribeByCode, {
        fileCode,
        language,
        hasSpeaker: hasSpeaker ? 'true' : 'false', // API 可能期望字符串 'true' 或 'false'
    });
};
/**
 * Fetches the first files and folders for the user.
 *
 * @param params - The parameters for fetching the file list.
 * @returns A promise that resolves to an ApiResponse containing FileEntity data.
 */
export const getFirstFilesAndFolders = (params) => {
    return axiosInstance.post(HttpApi.getFirstFilesAndFolders, params);
};
/**
 * Fetches the current files and folders for a specific folder.
 *
 * @param code - The code of the folder to fetch contents from.
 * @param pageNum - The page number for pagination (default: 1).
 * @param pageSize - The number of items per page (default: 15).
 * @returns A promise that resolves to an ApiResponse containing FileEntity data.
 */
export const getCurrentFilesAndFolders = (code, pageNum, pageSize) => {
    return axiosInstance.post(HttpApi.getCurrentFileAndFolderList, {
        conditionDto: { folderCode: code },
        pageParam: { pageNum, pageSize },
    });
};
/**
 * Fetches the dashboard files and folders for the user.
 *
 * @param pageNum - The page number for pagination (default: 1).
 * @param pageSize - The number of items per page (default: 15).
 * @returns A promise that resolves to an ApiResponse containing FileEntity data.
 */
export const getDashboardFilesAndFolders = () => {
    return axiosInstance.get(HttpApi.getDashboardFilesAndFolders, {});
};
/**
 * Creates a new folder.
 *
 * @param name - The name of the new folder.
 * @param parentCode - The code of the parent folder (empty string for root).
 * @returns A promise that resolves to an ApiResponse.
 */
export const createFolder = (name, parentCode) => {
    const params = parentCode ? { name, parentCode } : { name };
    return axiosInstance.post(HttpApi.addFolder, params);
};
