import { defineComponent, ref, computed } from 'vue';
import { IconFolder, IconCaretRight, IconCaretDown, IconCheck, } from '@arco-design/web-vue/es/icon';
export default defineComponent({
    name: 'FolderItem',
    components: {
        IconFolder,
        IconCaretRight,
        IconCaretDown,
        IconCheck,
    },
    props: {
        folder: {
            type: Object,
            required: true,
        },
        selectedFolder: {
            type: String,
            required: true,
        },
    },
    emits: ['select'],
    setup(props, { emit }) {
        const expanded = ref(false);
        const hasChildren = computed(() => props.folder.child && props.folder.child.length > 0);
        const toggleExpand = (event) => {
            event.stopPropagation();
            if (hasChildren.value) {
                expanded.value = !expanded.value;
            }
        };
        const selectFolder = () => {
            emit('select', props.folder.code);
        };
        return {
            expanded,
            hasChildren,
            toggleExpand,
            selectFolder,
        };
    },
});
