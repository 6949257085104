import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
    name: 'Footer',
    setup() {
        const router = useRouter();
        const navigateToHome = () => {
            router.push('/');
        };
        return {
            navigateToHome,
        };
    },
});
