import { createPinia } from 'pinia';
import { useAuthStore } from './authStore';
import { useFeedbackStore } from './feedbackStore';
import { useFileManagerStore } from './fileManagerStore';
import { useSubFolderStore } from './subFolderStore';
import { useFileDetailStore } from './fileDetailStore';
import { useUserStore } from './userStore';
import { useLanguageStore } from './languageStore';
import { useFeedbackDetailStore } from './feedbackDetailStore';
const pinia = createPinia();
export { pinia, useAuthStore, useFeedbackStore, useFileManagerStore, useSubFolderStore, useFileDetailStore, useUserStore, useLanguageStore, useFeedbackDetailStore, };
