import { defineStore } from 'pinia';
import { getLanguageList } from '@/api/language'; // 假设有这个 API 函数
export const useLanguageStore = defineStore('language', {
    state: () => ({
        languageList: [],
        selectedLanguage: {
            value: 'Automatic Detection',
            label: 'Automatic Detection',
        },
        isLoading: false,
        error: null,
    }),
    actions: {
        async fetchLanguageList() {
            this.isLoading = true;
            this.error = null;
            try {
                const response = await getLanguageList();
                this.languageList = response.data.map((item) => ({
                    key: item.key,
                    displayValue: item.displayValue,
                }));
                // 确保 "Automatic Detection" 选项存在于列表中
                if (!this.languageList.some((lang) => lang.key === 'Automatic Detection')) {
                    this.languageList.unshift({
                        key: 'Automatic Detection',
                        displayValue: 'Automatic Detection',
                    });
                }
            }
            catch (error) {
                console.error('Failed to fetch supported languages:', error);
                this.error = 'Failed to fetch supported languages';
            }
            finally {
                this.isLoading = false;
            }
        },
        setSelectedLanguage(language) {
            this.selectedLanguage = language;
            localStorage.setItem('selectedLanguage', JSON.stringify(language));
        },
        loadSelectedLanguage() {
            const storedLanguage = localStorage.getItem('selectedLanguage');
            if (storedLanguage) {
                this.selectedLanguage = JSON.parse(storedLanguage);
            }
        },
    },
});
