import { defineStore } from 'pinia';
import { ref, watch, computed } from 'vue';
import { login as apiLogin, register as apiRegister, logout as apiLogout, getUserInfo as apiGetUserInfo, sendVerificationCode as apiSendVerificationCode, updateEmail, verifyCode as apiVerifyCode, updateNickName as apiUpdateNickName, } from '@/api/auth';
import { getDeviceInfo } from '@/utils/device';
import { setAllowRequests } from '@/api/axios';
export const useAuthStore = defineStore('auth', () => {
    const token = ref(localStorage.getItem('auth_token') || null);
    const user = ref(JSON.parse(localStorage.getItem('user') || 'null'));
    watch(token, (newToken) => {
        if (newToken) {
            localStorage.setItem('auth_token', newToken);
        }
        else {
            localStorage.removeItem('auth_token');
        }
    });
    watch(user, (newUser) => {
        if (newUser) {
            localStorage.setItem('user', JSON.stringify(newUser));
        }
        else {
            localStorage.removeItem('user');
        }
    });
    const isLoggedIn = computed(() => !!token.value);
    const setUser = (newUser) => {
        user.value = newUser;
    };
    const setToken = (newToken) => {
        token.value = newToken;
    };
    const clearUser = () => {
        user.value = null;
        token.value = null;
    };
    const initAuth = async () => {
        const storedToken = localStorage.getItem('auth_token');
        if (storedToken) {
            setToken(storedToken);
            await getUserInfo();
        }
    };
    const login = async (payload) => {
        const response = await apiLogin(payload);
        if (response.code === 0) {
            token.value = response.data.token;
            user.value = response.data.userInfo;
            localStorage.setItem('auth_token', response.data.token);
        }
        return response;
    };
    const register = async (payload) => {
        return await apiRegister(payload);
    };
    const logout = async () => {
        try {
            const deviceInfo = await getDeviceInfo();
            const response = await apiLogout({ deviceId: deviceInfo.deviceId });
            if (response.code === 0) {
                clearUser();
                localStorage.removeItem('auth_token');
            }
            return response;
        }
        catch (error) {
            console.error('登出错误:', error);
            // 即使发生错误，也清除本地状态
            clearUser();
            localStorage.removeItem('auth_token');
            throw error; // 重新抛出错误，让调用者知道发生了问题
        }
        finally {
            setAllowRequests(false); // 确保在任何情况下都禁止请求
        }
    };
    const getUserInfo = async () => {
        const deviceInfo = await getDeviceInfo();
        const response = await apiGetUserInfo(deviceInfo.deviceId);
        if (response.code === 0) {
            setUser(response.data);
            return response;
        }
        else {
            throw new Error(response.msg || '获取用户信息失败');
        }
    };
    const sendVerificationCode = async (payload) => {
        return await apiSendVerificationCode(payload.email, payload.type);
    };
    const verifyCode = async (payload) => {
        const response = await apiVerifyCode({
            email: payload.email,
            code: payload.code,
        });
        if (response && response.code === 0) {
            return response.data;
        }
        else {
            throw new Error(response?.msg || '验证失败');
        }
    };
    const resetPassword = async (payload) => {
        return await resetPassword(payload);
    };
    const updatePassword = async (payload) => {
        return await updatePassword(payload);
    };
    const changeEmail = async (payload) => {
        const response = await updateEmail(payload.newEmail, payload.verificationCode);
        if (response.code === 0 && user.value) {
            setUser({ ...user.value, email: payload.newEmail });
        }
        return response;
    };
    const updateNickName = async (newNickName) => {
        const response = await apiUpdateNickName(newNickName);
        if (response.code === 0 && user.value) {
            setUser({ ...user.value, nickName: newNickName });
        }
        return response;
    };
    const $reset = () => {
        token.value = null;
        user.value = null;
        localStorage.removeItem('auth_token');
        localStorage.removeItem('user');
    };
    const checkAuthStatus = async () => {
        if (isLoggedIn.value && user.value) {
            return true;
        }
        else if (token.value) {
            try {
                await getUserInfo();
                return true;
            }
            catch (error) {
                console.error('Failed to get user info:', error);
                clearUser();
                return false;
            }
        }
        return false;
    };
    return {
        token,
        user,
        isLoggedIn,
        setUser,
        setToken,
        clearUser,
        initAuth,
        login,
        register,
        logout,
        getUserInfo,
        sendVerificationCode,
        verifyCode,
        resetPassword,
        updatePassword,
        changeEmail,
        $reset,
        checkAuthStatus,
        updateNickName,
    };
});
