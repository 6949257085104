import { defineStore } from 'pinia';
import { getCurrentFilesAndFolders, createFolder, deleteFolderOrFile, moveFolderOrFile, renameFolderOrFile, getFirstFilesAndFolders, getTranscribedFilesByCode, uploadFile, } from '@/api/file_folder';
import { Message } from '@arco-design/web-vue';
export const useSubFolderStore = defineStore('subFolder', {
    state: () => ({
        subFolder: {
            files: [],
            totalFiles: 0,
            currentPage: 1,
            pageSize: 10,
            currentFolderCode: '',
            currentFolderName: '',
        },
        folderDetail: {
            folderCode: '',
            folderName: '',
            parentFolderCode: '',
            createTime: 0,
            // Add any other folder-related properties you need
        },
        fileDetail: {
            fileName: '',
            status: '',
            statusText: '',
            duration: 0,
            createTime: 0,
            expiredTime: 0,
            previewUrl: '',
            transcriptSegments: [],
            code: '',
            translatedFileCode: '',
        },
    }),
    getters: {
        uniqueSpeakers: (state) => [
            ...new Set(state.fileDetail.transcriptSegments.map((segment) => segment.speaker)),
        ],
    },
    actions: {
        setFolderDetail(payload) {
            Object.assign(this.folderDetail, payload);
        },
        setSubFolder(payload) {
            Object.assign(this.subFolder, payload);
        },
        async fetchSubFolderFiles(params) {
            this.setFolderDetail({
                folderCode: params.folderCode,
            });
            this.setSubFolder({
                currentFolderCode: params.folderCode,
            });
            try {
                console.log('Fetching file manager files with params:', params);
                const response = await getCurrentFilesAndFolders(params.folderCode, params.pageNum, params.pageSize);
                console.log('File manager files response:', response);
                this.subFolder.files = response.data.dataList || [];
                this.subFolder.totalFiles = response.data.pageParam?.totalElements || 0;
                this.subFolder.currentPage = params.pageNum;
                this.subFolder.pageSize = params.pageSize;
            }
            catch (error) {
                console.error('Error fetching file manager files:', error);
                throw error;
            }
        },
        async changeSubFolderPage(page) {
            this.subFolder.currentPage = page;
            try {
                const response = await getFirstFilesAndFolders({
                    pageNum: page,
                    pageSize: this.subFolder.pageSize,
                });
                this.subFolder.files = response.data.dataList || [];
                this.subFolder.totalFiles = response.data.pageParam?.totalElements || 0;
            }
            catch (error) {
                console.error('Error fetching file manager files:', error);
            }
        },
        async changeSubFolderPageSize(size) {
            this.subFolder.pageSize = size;
            this.subFolder.currentPage = 1;
            await this.fetchSubFolderFiles({
                folderCode: this.subFolder.currentFolderCode,
                pageNum: 1,
                pageSize: size,
            });
        },
        async renameFile({ type, code, newName, }) {
            try {
                await renameFolderOrFile(type, code, newName);
                await this.fetchSubFolderFiles({
                    folderCode: this.subFolder.currentFolderCode,
                    pageNum: this.subFolder.currentPage,
                    pageSize: this.subFolder.pageSize,
                });
            }
            catch (error) {
                console.error('Error renaming file:', error);
            }
        },
        async moveFile({ type, code, desCode, }) {
            try {
                await moveFolderOrFile(type, code, desCode);
                await this.fetchSubFolderFiles({
                    folderCode: this.subFolder.currentFolderCode,
                    pageNum: this.subFolder.currentPage,
                    pageSize: this.subFolder.pageSize,
                });
            }
            catch (error) {
                console.error('Error moving file:', error);
            }
        },
        async deleteFile({ type, code, }) {
            try {
                await deleteFolderOrFile(type, code);
                await this.fetchSubFolderFiles({
                    folderCode: this.subFolder.currentFolderCode,
                    pageNum: this.subFolder.currentPage,
                    pageSize: this.subFolder.pageSize,
                });
            }
            catch (error) {
                console.error('Error deleting file:', error);
            }
        },
        async createFolder({ folderName, folderCode, }) {
            try {
                await createFolder(folderName, folderCode);
                await this.fetchSubFolderFiles({
                    folderCode: this.subFolder.currentFolderCode,
                    pageNum: this.subFolder.currentPage,
                    pageSize: this.subFolder.pageSize,
                });
            }
            catch (error) {
                console.error('Error creating folder:', error);
            }
        },
        async fetchFileDetail(params) {
            this.fileDetail = {
                fileName: params.name,
                status: params.status,
                statusText: params.status === 'processing' ? '处理中' : '已完成',
                duration: Number(params.duration) || 0,
                createTime: Number(params.createTime) || 0,
                expiredTime: Number(params.expiredTime) || 0,
                previewUrl: params.previewUrl,
                code: params.code,
                translatedFileCode: params.translatedFileCode,
                transcriptSegments: [],
            };
            try {
                const response = await getTranscribedFilesByCode(params.translatedFileCode);
                if (response.data && Array.isArray(response.data.transcriptions)) {
                    this.fileDetail.transcriptSegments = response.data.transcriptions;
                }
                else {
                    throw new Error('Unexpected response data format');
                }
            }
            catch (error) {
                console.error('Error fetching file details:', error);
                throw error;
            }
        },
        async saveFileEdits(updatedTranscriptions) {
            try {
                const updatedEntity = {
                    transcriptions: updatedTranscriptions,
                };
                await uploadFile(updatedEntity, this.fileDetail.translatedFileCode);
                this.fileDetail.transcriptSegments = updatedTranscriptions;
                Message.success('保存成功');
            }
            catch (error) {
                console.error('Error saving edits:', error);
                Message.error('保存失败');
                throw error;
            }
        },
    },
});
