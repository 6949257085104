import FingerprintJS from '@fingerprintjs/fingerprintjs';
/**
 * Generates and retrieves the device information.
 * Utilizes FingerprintJS for generating a unique device ID.
 *
 * @returns A promise that resolves to the device information.
 */
export const getDeviceInfo = async () => {
    // Check if deviceId is already stored
    let deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
        // Initialize FingerprintJS
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        deviceId = result.visitorId;
        localStorage.setItem('deviceId', deviceId || '');
    }
    // Construct device information
    const deviceInfo = {
        deviceId: deviceId || '',
        terminal: 'web',
        sysVersion: navigator.userAgent,
        applyVersion: process.env.VUE_APP_VERSION || '1.0.0',
        deviceName: navigator.platform,
        networkType: 'unknown', // Enhance this if you can detect the actual network type
    };
    return deviceInfo;
};
