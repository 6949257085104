import { defineStore } from 'pinia';
import { getFeedbackList, submitFeedback } from '@/api/feedback';
import { Message } from '@arco-design/web-vue';
export const useFeedbackStore = defineStore('feedback', {
    state: () => ({
        feedbacks: [],
        totalFeedbacks: 0,
        currentPage: 1,
        pageSize: 10,
        filterStatus: '',
        searchKeyword: '',
    }),
    actions: {
        async fetchFeedbacks(params) {
            try {
                const response = await getFeedbackList({
                    pageNum: params?.pageNum || this.currentPage,
                    pageSize: params?.pageSize || this.pageSize,
                    status: params?.status || this.filterStatus,
                    issueDesc: params?.issueDesc || this.searchKeyword,
                });
                this.feedbacks = Array.isArray(response.data?.dataList)
                    ? response.data.dataList
                    : [];
                this.totalFeedbacks = response.data?.pageParam?.totalElements ?? 0;
                this.currentPage = params?.pageNum || this.currentPage;
                this.pageSize = params?.pageSize || this.pageSize;
            }
            catch (error) {
                console.error('Error fetching feedbacks:', error);
                Message.error('获取反馈列表失败，请稍后重试。');
            }
        },
        async submitFeedback(feedbackData, files) {
            try {
                const response = await submitFeedback(feedbackData, files || []);
                if (response.code === 0) {
                    Message.success('反馈提交成功');
                    return true;
                }
                else {
                    throw new Error(response.msg || '提交反馈失败');
                }
            }
            catch (error) {
                console.error('Error submitting feedback:', error);
                Message.error('提交反馈失败，请稍后重试。');
                return false;
            }
        },
        setFilterStatus(status) {
            this.filterStatus = status;
        },
        setSearchKeyword(keyword) {
            this.searchKeyword = keyword;
        },
        resetFilters() {
            this.filterStatus = '';
            this.searchKeyword = '';
            this.currentPage = 1;
            this.fetchFeedbacks();
        },
    },
});
