import { defineStore } from 'pinia';
import { getDashboardFilesAndFolders, createFolder, deleteFolderOrFile, moveFolderOrFile, renameFolderOrFile, getFirstFilesAndFolders, getTranscribedFilesByCode, uploadFile, } from '@/api/file_folder';
import { Message } from '@arco-design/web-vue';
export const useFileManagerStore = defineStore('fileManager', {
    state: () => ({
        dashboard: {
            files: [],
            totalFiles: 0,
        },
        fileManager: {
            files: [],
            totalFiles: 0,
            currentPage: 1,
            pageSize: 10,
            currentFolderCode: '',
            currentFolderName: '',
        },
        subFolder: {
            files: [],
            totalFiles: 0,
            currentPage: 1,
            pageSize: 10,
            currentFolderCode: '',
            currentFolderName: '',
        },
        fileDetail: {
            fileName: '',
            status: '',
            statusText: '',
            duration: 0,
            createTime: 0,
            expiredTime: 0,
            previewUrl: '',
            transcriptSegments: [],
            code: '',
            translatedFileCode: '',
        },
    }),
    getters: {
        dashboardFiles: (state) => state.dashboard.files,
        dashboardTotalFiles: (state) => state.dashboard.totalFiles,
        fileManagerFiles: (state) => state.fileManager.files,
        fileManagerTotalFiles: (state) => state.fileManager.totalFiles,
        fileManagerCurrentPage: (state) => state.fileManager.currentPage,
        fileManagerPageSize: (state) => state.fileManager.pageSize,
        uniqueSpeakers: (state) => [
            ...new Set(state.fileDetail.transcriptSegments.map((segment) => segment.speaker)),
        ],
    },
    actions: {
        async fetchDashboardFiles() {
            try {
                const response = await getDashboardFilesAndFolders();
                console.log('API response:', response);
                if (response.data && Array.isArray(response.data)) {
                    this.dashboard.files = response.data;
                    this.dashboard.totalFiles = response.data.length;
                }
                else {
                    console.error('Unexpected API response structure:', response);
                    this.dashboard.files = [];
                    this.dashboard.totalFiles = 0;
                }
                console.log('Updated dashboard files:', this.dashboard.files);
            }
            catch (error) {
                console.error('Error fetching dashboard files:', error);
                this.dashboard.files = [];
                this.dashboard.totalFiles = 0;
            }
        },
        async fetchFileManagerFiles(params) {
            try {
                console.log('Fetching file manager files with params:', params);
                const response = await getFirstFilesAndFolders({
                    pageNum: params.pageNum,
                    pageSize: params.pageSize,
                });
                console.log('File manager files response:', response);
                this.fileManager.files = response.data.dataList || [];
                this.fileManager.totalFiles =
                    response.data.pageParam?.totalElements || 0;
                this.fileManager.currentPage = params.pageNum;
                this.fileManager.pageSize = params.pageSize;
            }
            catch (error) {
                console.error('Error fetching file manager files:', error);
                throw error;
            }
        },
        async changeFileManagerPage(page) {
            this.fileManager.currentPage = page;
            try {
                const response = await getFirstFilesAndFolders({
                    pageNum: page,
                    pageSize: this.fileManager.pageSize,
                });
                this.fileManager.files = response.data.dataList || [];
                this.fileManager.totalFiles =
                    response.data.pageParam?.totalElements || 0;
            }
            catch (error) {
                console.error('Error fetching file manager files:', error);
            }
        },
        async changeFileManagerPageSize(size) {
            this.fileManager.pageSize = size;
            this.fileManager.currentPage = 1;
            await this.fetchFileManagerFiles({
                pageNum: 1,
                pageSize: size,
            });
        },
        async renameFile({ type, code, newName, }) {
            try {
                await renameFolderOrFile(type, code, newName);
                await this.fetchFileManagerFiles({
                    pageNum: this.fileManager.currentPage,
                    pageSize: this.fileManager.pageSize,
                });
            }
            catch (error) {
                console.error('Error renaming file:', error);
                throw error;
            }
        },
        async moveFile({ type, code, desCode, }) {
            try {
                await moveFolderOrFile(type, code, desCode);
                await this.fetchFileManagerFiles({
                    pageNum: this.fileManager.currentPage,
                    pageSize: this.fileManager.pageSize,
                });
            }
            catch (error) {
                console.error('Error moving file:', error);
                throw error;
            }
        },
        async deleteFile({ type, code, }) {
            try {
                await deleteFolderOrFile(type, code);
                await this.fetchFileManagerFiles({
                    pageNum: this.fileManager.currentPage,
                    pageSize: this.fileManager.pageSize,
                });
            }
            catch (error) {
                console.error('Error deleting file:', error);
                throw error;
            }
        },
        async createFolder({ folderName, folderCode, }) {
            try {
                await createFolder(folderName, folderCode);
                await this.fetchFileManagerFiles({
                    pageNum: this.fileManager.currentPage,
                    pageSize: this.fileManager.pageSize,
                });
            }
            catch (error) {
                console.error('Error creating folder:', error);
            }
        },
        async fetchFileDetail(params) {
            this.fileDetail = {
                fileName: params.name,
                status: params.status,
                statusText: params.status === 'processing' ? '处理中' : '已完成',
                duration: Number(params.duration) || 0,
                createTime: Number(params.createTime) || 0,
                expiredTime: Number(params.expiredTime) || 0,
                previewUrl: params.previewUrl,
                code: params.code,
                translatedFileCode: params.translatedFileCode,
                transcriptSegments: [],
            };
            try {
                const response = await getTranscribedFilesByCode(params.translatedFileCode);
                if (response.data && Array.isArray(response.data.transcriptions)) {
                    this.fileDetail.transcriptSegments = response.data.transcriptions;
                }
                else {
                    throw new Error('Unexpected response data format');
                }
            }
            catch (error) {
                console.error('Error fetching file details:', error);
                throw error;
            }
        },
        async saveFileEdits(updatedTranscriptions) {
            try {
                const updatedEntity = {
                    transcriptions: updatedTranscriptions,
                };
                await uploadFile(updatedEntity, this.fileDetail.translatedFileCode);
                this.fileDetail.transcriptSegments = updatedTranscriptions;
                Message.success('保存成功');
            }
            catch (error) {
                console.error('Error saving edits:', error);
                Message.error('保存失败');
                throw error;
            }
        },
    },
});
