import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-76b5f763"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "folder-item" };
const _hoisted_2 = { class: "folder-info" };
const _hoisted_3 = { class: "folder-name" };
const _hoisted_4 = {
    key: 0,
    class: "subfolder-list"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_icon_caret_right = _resolveComponent("icon-caret-right");
    const _component_icon_caret_down = _resolveComponent("icon-caret-down");
    const _component_icon_folder = _resolveComponent("icon-folder");
    const _component_icon_check = _resolveComponent("icon-check");
    const _component_FolderItem = _resolveComponent("FolderItem", true);
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
            class: _normalizeClass(["folder-content", { 'selected-folder': _ctx.selectedFolder === _ctx.folder.code }]),
            onClick: _cache[1] || (_cache[1] =
                //@ts-ignore
                (...args) => (_ctx.selectFolder && _ctx.selectFolder(...args)))
        }, [
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", {
                    class: "folder-icon",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(
                    //@ts-ignore
                    (...args) => (_ctx.toggleExpand && _ctx.toggleExpand(...args)), ["stop"]))
                }, [
                    (!_ctx.expanded && _ctx.hasChildren)
                        ? (_openBlock(), _createBlock(_component_icon_caret_right, { key: 0 }))
                        : _createCommentVNode("", true),
                    (_ctx.expanded && _ctx.hasChildren)
                        ? (_openBlock(), _createBlock(_component_icon_caret_down, { key: 1 }))
                        : _createCommentVNode("", true)
                ]),
                _createVNode(_component_icon_folder),
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.folder.name), 1)
            ]),
            (_ctx.selectedFolder === _ctx.folder.code)
                ? (_openBlock(), _createBlock(_component_icon_check, {
                    key: 0,
                    class: "check-icon"
                }))
                : _createCommentVNode("", true)
        ], 2),
        (_ctx.expanded && _ctx.hasChildren)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.folder.child, (subFolder) => {
                    return (_openBlock(), _createBlock(_component_FolderItem, {
                        key: subFolder.code,
                        folder: subFolder,
                        "selected-folder": _ctx.selectedFolder,
                        onSelect: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('select', $event)))
                    }, null, 8, ["folder", "selected-folder"]));
                }), 128))
            ]))
            : _createCommentVNode("", true)
    ]));
}
