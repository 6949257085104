import { createApp } from 'vue';
import App from './App.vue';
import { pinia } from './stores';
import ArcoVue from '@arco-design/web-vue';
import '@arco-design/web-vue/dist/arco.css';
import './styles/global.css';
import router from './router';
import i18n from './i18n';
import { useAuthStore } from '@/stores/authStore';
import { setAllowRequests } from '@/api/axios';
// Set the primary color using CSS variables
document.documentElement.style.setProperty('--color-primary', '#7a52f4');
const app = createApp(App);
app.use(pinia);
app.use(ArcoVue);
app.use(router);
app.use(i18n);
// Register Service Worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker
            .register('/service-worker.js')
            .then((registration) => {
            console.log('ServiceWorker registered: ', registration);
        })
            .catch((error) => {
            console.error('ServiceWorker registration failed: ', error);
        });
    });
}
app.mount('#app');
app.config.errorHandler = (err, vm, info) => {
    console.error('Global error:', err, info);
    if (err instanceof Error && err.message === '鉴权失败，请您确认后再操作') {
        const authStore = useAuthStore();
        authStore.clearUser();
        setAllowRequests(false);
        router.push('/login');
    }
};
