<template>
  <div class="price-page">
    <section class="pricing-plans">
      <h1 class="pricing-title">价格</h1>
      <p class="pricing-subtitle">选择适合您需求的套餐</p>

      <div class="billing-toggle">
        <button
          :class="['toggle-button', { active: billingCycle === 'monthly' }]"
          @click="billingCycle = 'monthly'"
        >
          月度
        </button>
        <button
          :class="['toggle-button', { active: billingCycle === 'yearly' }]"
          @click="billingCycle = 'yearly'"
        >
          年度
        </button>
      </div>

      <div class="price-cards">
        <div v-for="plan in plans" class="price-card" :key="plan.name">
          <div class="card-content">
            <div class="centered-content">
              <h2 class="card-title">
                {{ plan.name }}
              </h2>
              <div class="price-section">
                <div class="price-container">
                  <span class="currency">$</span>
                  <span class="amount">
                    {{
                      billingCycle === 'monthly'
                        ? plan.monthlyPrice
                        : plan.yearlyPrice
                    }}
                  </span>
                  <span class="period"
                    >/{{ billingCycle === 'monthly' ? '月' : '年' }}</span
                  >
                </div>
                <div v-if="plan.discount" class="discount-tag">
                  {{ plan.discount }}
                </div>
              </div>
              <p class="description">
                {{ plan.description }}
              </p>
              <ul class="features">
                <li v-for="feature in plan.features" :key="feature">
                  <icon-check /> {{ feature }}
                </li>
              </ul>
            </div>
          </div>
          <a-button
            class="action-button"
            :type="plan.name === '专业套餐' ? 'outline' : 'outline'"
          >
            {{ plan.buttonText }}
          </a-button>
        </div>
      </div>
    </section>

    <!-- 保持原有的对比表格部分不变 -->
    <section class="plan-comparison">
      <h2>套餐对比</h2>
      <div class="comparison-wrapper">
        <table class="custom-table desktop-table">
          <colgroup>
            <col style="width: 20%" />
            <col style="width: 20%" />
            <col style="width: 20%" />
            <col style="width: 20%" />
          </colgroup>
          <thead>
            <tr>
              <th />
              <th v-for="plan in plans" :key="plan.name">
                {{ plan.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in comparisonData" :key="index">
              <td class="feature-cell">
                {{ row.feature }}
              </td>
              <td v-for="plan in plans" :key="plan.key">
                <icon-check-circle-fill
                  v-if="row[plan.key] === true"
                  :style="{ color: '#7C5CFC', fontSize: '20px' }"
                />
                <span v-else>{{ row[plan.key] }}</span>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- 移动端套餐对比 -->
        <div class="mobile-comparison">
          <div class="plan-selector">
            <span
              v-for="plan in plans"
              :key="plan.key"
              :class="['plan-option', { active: selectedPlan === plan.key }]"
              @click="selectedPlan = plan.key"
            >
              {{ plan.name }}
            </span>
          </div>
          <table class="custom-table mobile-table">
            <tbody>
              <tr v-for="(row, index) in comparisonData" :key="index">
                <td class="feature-cell">
                  {{ row.feature }}
                </td>
                <td>
                  <icon-check-circle-fill
                    v-if="row[selectedPlan] === true"
                    :style="{ color: '#7C5CFC', fontSize: '20px' }"
                  />
                  <span v-else>{{ row[selectedPlan] }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { IconCheck, IconCheckCircleFill } from '@arco-design/web-vue/es/icon'

export default defineComponent({
  name: 'PricePage',
  components: {
    IconCheck,
    IconCheckCircleFill,
  },
  setup() {
    const billingCycle = ref('monthly')

    const plans = ref([
      {
        name: '免费套餐',
        key: 'free',
        monthlyPrice: '0',
        yearlyPrice: '0',
        description: '适用于想要更多体验特超能力的个人',
        features: ['120分钟/月', '每月重置时间'],
        buttonText: '开始使用',
      },
      {
        name: '专业套餐',
        key: 'pro',
        monthlyPrice: '8.25',
        yearlyPrice: '99',
        description: '适用于想要更多重要分析的专业人士',
        features: ['2200分钟/月', '每月重置时间'],
        buttonText: '获取套餐',
        discount: '7.5折',
      },
      {
        name: '商业套餐',
        key: 'business',
        monthlyPrice: '44.00',
        yearlyPrice: '528',
        description: '适用于企业或团队',
        features: ['无限分钟/月'],
        buttonText: '获取套餐',
        discount: '7.5折',
      },
    ])

    const comparisonData = ref([
      {
        feature: '转写时长',
        free: '120分钟',
        pro: '2200分钟',
        business: '无限',
      },
      {
        feature: '时长刷新',
        free: '每月刷新',
        pro: '每月刷新',
        business: '无需刷新',
      },
      {
        feature: '限制',
        free: '文件大小限制1GB（音频）/5GB（视频），音频或视频长度限制5小时。',
        pro: '文件大小限制1GB（音频）/5GB（视频），音频或视频长度限制5小时。',
        business:
          '文件大小限制1GB（音频）/5GB（视频），音频或视频长度限制5小时。',
      },
      {
        feature: '导入音/视频转写',
        free: true,
        pro: true,
        business: true,
      },
      {
        feature: '实时录音转写',
        free: true,
        pro: true,
        business: true,
      },
      {
        feature: '在��会议转写',
        free: true,
        pro: true,
        business: true,
      },
      {
        feature: '翻译',
        free: true,
        pro: true,
        business: true,
      },
      {
        feature: '导出',
        free: true,
        pro: true,
        business: true,
      },
    ])

    const selectedPlan = ref('free')

    return {
      billingCycle,
      plans,
      comparisonData,
      selectedPlan,
    }
  },
})
</script>

<style scoped>
.price-page {
  margin: 0 auto;
  padding: 40px 20px;
}

.pricing-plans {
  margin-bottom: 60px;
  text-align: center;
}

.pricing-title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 16px;
}

.pricing-subtitle {
  font-size: 1.2em;
  color: #4e5969;
  margin-bottom: 40px;
}

.billing-toggle {
  display: inline-flex;
  background-color: #fff;
  border: 1px solid #7c5cfc;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 40px;
}

.toggle-button {
  width: 120px;
  padding: 10px 24px;
  background-color: transparent;
  border: none;
  color: #7c5cfc;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.toggle-button.active {
  background-color: #7c5cfc;
  color: white;
}

.price-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.price-card {
  background-color: #fff;
  border-radius: 16px;
  padding: 24px;
  width: 230px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  text-align: center;
}

.price-section {
  position: relative;
  margin-bottom: 8px;
}

.price-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.discount-tag {
  position: absolute;
  top: -20px;
  right: -40px;
  background-color: #fff8e1;
  color: #ffb300;
  padding: 6px 12px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: bold;
}

.currency {
  font-size: 24px;
  font-weight: bold;
  color: #7c5cfc;
  margin-right: 4px;
}

.amount {
  font-size: 30px;
  font-weight: bold;
  color: #7c5cfc;
  line-height: 1.5;
}

.period {
  font-size: 12px;
  color: #7c5cfc;
  margin-left: 4px;
}

.description {
  font-size: 14px;
  color: #4e5969;
  margin-bottom: 24px;
  text-align: center;
  flex-grow: 1;
}

.features {
  list-style-type: none;
  padding: 0;
  margin: 0 0 24px;
  text-align: left;
}

.features li {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: 14px;
  color: #1d2129;
}

.features li :deep(.icon) {
  color: #7c5cfc;
  margin-right: 8px;
}

.action-button {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  font-size: 16px;
  color: #7c5cfc;
  border-color: #7c5cfc;
  margin-top: auto;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .price-cards {
    flex-direction: column;
    align-items: center;
  }

  .price-card {
    width: 100%;
    max-width: 280px;
  }
}

.comparison-wrapper {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 保留阴影，移除边框相关的阴影 */
  overflow-x: auto;
}

.plan-comparison {
  margin-bottom: 60px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  font-size: 2em;
  margin-bottom: 20px;
}
h2 {
  text-align: center;
  margin-bottom: 20px;
}

.custom-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
  overflow: hidden;
  table-layout: fixed;
}

.custom-table th,
.custom-table td {
  padding: 16px;
  text-align: center;
  vertical-align: top;
  word-wrap: break-word;
  overflow-wrap: break-word;
  /* 移除以下注释掉的边框样式 */
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
}

.custom-table th {
  background-color: #ffffff;
  font-weight: bold;
  color: #1d2129;
  font-size: 16px;
  white-space: nowrap;
}

.custom-table td {
  background-color: #f9f9ff;
  color: #4e5969;
  font-size: 14px;
  line-height: 1.4;
}

.custom-table tr:not(:last-child) td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* 保留行之间的分隔线 */
}

.custom-table td:first-child,
.custom-table th:first-child {
  text-align: left;
}

.feature-cell {
  font-weight: bold;
  color: #1d2129;
}

@media (max-width: 1024px) {
  .pricing-plans,
  .plan-comparison {
    max-width: 800px;
  }
}

@media (max-width: 768px) {
  .price-cards {
    flex-direction: column;
    align-items: center;
  }

  .price-card {
    width: 100%;
    max-width: 200px;
  }

  .comparison-wrapper {
    padding: 10px;
  }

  .custom-table th,
  .custom-table td {
    padding: 10px;
    font-size: 12px;
  }

  .custom-table col:first-child {
    width: 30%;
  }

  .custom-table col:not(:first-child) {
    width: 23.33%;
  }
}

.desktop-table {
  display: table;
}

.mobile-comparison {
  display: none;
}

.plan-selector {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.plan-option {
  padding: 10px 0;
  cursor: pointer;
  position: relative;
  font-size: 16px;
  color: #4e5969;
}

.plan-option.active {
  color: #7c5cfc;
  font-weight: bold;
}

.plan-option.active::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #7c5cfc;
}

.mobile-table {
  width: 100%;
}

@media (max-width: 768px) {
  .desktop-table {
    display: none;
  }

  .mobile-comparison {
    display: block;
  }

  .plan-option {
    font-size: 14px;
  }

  .mobile-table td {
    padding: 12px;
    font-size: 14px;
  }
}
</style>
