// src/utils/preloader.ts
const CACHE_NAME = 'resource-manifest';
const BASE_PATH = '/web'; // 根据实际子目录调整
let cacheHits = 0;
let cacheMisses = 0;
export const checkCacheAndPreload = async (progressCallback) => {
    try {
        const manifest = await fetchResourceManifest();
        console.log('Resource manifest:', manifest);
        const resourcesToLoad = await getResourcesToLoad(manifest);
        console.log('Resources to load:', resourcesToLoad);
        if (resourcesToLoad.length > 0) {
            await preloadMobileApp(resourcesToLoad, progressCallback);
        }
        else {
            progressCallback(100);
        }
        await verifyResourcesLoaded(manifest);
        console.log(`Preloading complete. Cache usage: ${cacheHits} hits, ${cacheMisses} misses`);
    }
    catch (error) {
        console.error('Error in checkCacheAndPreload:', error);
        throw error;
    }
};
const fetchResourceManifest = async () => {
    try {
        const response = await fetch(`${BASE_PATH}/resource-manifest.json`);
        if (!response.ok) {
            throw new Error(`Failed to fetch resource manifest: ${response.status} ${response.statusText}`);
        }
        return response.json();
    }
    catch (error) {
        console.error('Error fetching resource manifest:', error);
        throw error;
    }
};
const getResourcesToLoad = async (manifest) => {
    if ('caches' in window) {
        const cache = await caches.open(CACHE_NAME);
        const cachedUrls = new Set((await cache.keys()).map((req) => req.url));
        return manifest.filter((resource) => !cachedUrls.has(getFullUrl(resource.url)));
    }
    return manifest;
};
const preloadMobileApp = async (resources, progressCallback) => {
    const totalResources = resources.length;
    let loadedResources = 0;
    const preloadPromises = resources.map(async (resource) => {
        try {
            const startTime = performance.now();
            await preloadResource(resource);
            const endTime = performance.now();
            console.log(`Resource ${getFullUrl(resource.url)} loaded in ${endTime - startTime}ms`);
            loadedResources++;
            progressCallback(Math.round((loadedResources / totalResources) * 100));
        }
        catch (error) {
            console.error(`Failed to preload ${getFullUrl(resource.url)}:`, error);
        }
    });
    await Promise.all(preloadPromises);
};
const preloadResource = async (resource) => {
    const fullUrl = getFullUrl(resource.url);
    console.log(`Attempting to preload: ${fullUrl}`);
    try {
        if ('caches' in window) {
            const cache = await caches.open(CACHE_NAME);
            let response = await cache.match(fullUrl);
            if (response) {
                console.log(`Using cached resource: ${fullUrl}`);
                cacheHits++;
            }
            else {
                console.log(`Fetching resource: ${fullUrl}`);
                response = await fetch(fullUrl);
                if (!response.ok) {
                    throw new Error(`Failed to preload ${fullUrl}: ${response.status} ${response.statusText}`);
                }
                await cache.put(fullUrl, response.clone());
                cacheMisses++;
            }
        }
        else {
            // 如果不支持 Cache API，直接获取资源
            const response = await fetch(fullUrl);
            if (!response.ok) {
                throw new Error(`Failed to preload ${fullUrl}: ${response.status} ${response.statusText}`);
            }
            cacheMisses++;
        }
        console.log(`Successfully preloaded: ${fullUrl}`);
        if (resource.type === 'script') {
            await new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = fullUrl;
                script.onload = resolve;
                script.onerror = reject;
                document.head.appendChild(script);
            });
        }
    }
    catch (error) {
        console.error(`Failed to preload ${fullUrl}:`, error);
        cacheMisses++;
    }
};
const verifyResourcesLoaded = async (manifest) => {
    for (const resource of manifest) {
        const fullUrl = getFullUrl(resource.url);
        try {
            const response = await fetch(fullUrl, { method: 'HEAD' });
            if (!response.ok) {
                console.warn(`Resource not properly loaded: ${fullUrl}`);
            }
            else {
                console.log(`Resource verified: ${fullUrl}`);
            }
        }
        catch (error) {
            console.error(`Failed to verify resource: ${fullUrl}`, error);
        }
    }
};
const getFullUrl = (url) => {
    if (url.startsWith('http://') || url.startsWith('https://')) {
        return url;
    }
    return new URL(BASE_PATH + url, window.location.origin).href;
};
export const monitorNetworkRequests = () => {
    const originalFetch = window.fetch;
    window.fetch = async function (...args) {
        const url = args[0];
        const startTime = performance.now();
        try {
            const response = await originalFetch.apply(this, args);
            const endTime = performance.now();
            const duration = endTime - startTime;
            const cacheStatus = response.headers.get('x-cache') || 'unknown';
            console.log(`Resource: ${url}, Cache: ${cacheStatus}, Load time: ${duration.toFixed(2)}ms`);
            return response;
        }
        catch (error) {
            console.error(`Failed to fetch ${url}:`, error);
            throw error;
        }
    };
};
export const reportCacheUsage = () => {
    const totalRequests = cacheHits + cacheMisses;
    const cacheHitRate = totalRequests > 0 ? (cacheHits / totalRequests) * 100 : 0;
    console.log(`
    Cache Usage Report:
    Total Requests: ${totalRequests}
    Cache Hits: ${cacheHits}
    Cache Misses: ${cacheMisses}
    Cache Hit Rate: ${cacheHitRate.toFixed(2)}%
  `);
};
export const resetCacheStats = () => {
    cacheHits = 0;
    cacheMisses = 0;
};
