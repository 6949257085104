import { defineComponent, ref, onMounted } from 'vue';
import { checkCacheAndPreload, monitorNetworkRequests, reportCacheUsage, } from '@/utils/preloader';
export default defineComponent({
    name: 'HomePage',
    setup() {
        const isLoading = ref(false);
        const isPreloaded = ref(false);
        const showLoadingModal = ref(false);
        const loadingProgress = ref(0);
        const features = ref([
            {
                icon: 'icon_transcribe.png',
                title: '转录音视频文件',
                description: '支持导入本地音频或视频文件,或通过粘贴YouTube链接进行在线转录。',
            },
            {
                icon: 'icon_meeting.png',
                title: '转录在线会议',
                description: '适用于会议的实时转录,支持Zoom、Google Meet和Microsoft Teams在线会议。',
            },
            {
                icon: 'icon_edit.png',
                title: '慢放编辑',
                description: '使用富文本编辑器通过聆听慢放音频来纠正小错误并更改发言者姓名。',
            },
            {
                icon: 'icon_export.png',
                title: '丰富的导出选项',
                description: '将文件导出为多种文本格式文件(TXT、SRT、Word和PDF文本)。',
            },
        ]);
        const preloadMobileApp = async () => {
            if (localStorage.getItem('webAppPreloaded')) {
                isPreloaded.value = true;
                return;
            }
            try {
                showLoadingModal.value = true;
                isLoading.value = true;
                monitorNetworkRequests();
                await checkCacheAndPreload((progress) => {
                    loadingProgress.value = progress;
                    console.log(`Preload progress: ${progress}%`);
                });
                reportCacheUsage();
                isPreloaded.value = true;
                localStorage.setItem('webAppPreloaded', 'true');
                console.log('App resources preloaded successfully');
            }
            catch (error) {
                console.error('Failed to preload App:', error);
            }
            finally {
                showLoadingModal.value = false;
                isLoading.value = false;
            }
        };
        const handleNavigate = () => {
            // 导航逻辑保持不变
        };
        const isMobileWeb = () => {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        };
        onMounted(() => {
            if (isMobileWeb()) {
                preloadMobileApp();
            }
        });
        return {
            handleNavigate,
            isLoading,
            isPreloaded,
            showLoadingModal,
            loadingProgress,
            features,
        };
    },
});
