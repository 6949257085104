import axios from 'axios';
import { Message } from '@arco-design/web-vue';
import router from '@/router';
import { useAuthStore } from '@/stores/authStore';
export const baseURL = process.env.VUE_APP_API_BASE_URL || 'https://test-api.xtranscript.com';
const axiosInstance = axios.create({
    baseURL,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
});
// 声明一个变量来存储 allowRequests 的值
let allowRequests = true;
const pendingRequests = [];
let isRefreshing = false;
let isRedirecting = false;
// 导出一个函数来更新 allowRequests 的值
export const setAllowRequests = (value) => {
    allowRequests = value;
    if (!value) {
        // 取消所有正在进行的请求
        pendingRequests.forEach((source) => source.cancel('Operation canceled due to logout'));
        pendingRequests.length = 0;
    }
};
const clearUserAndRedirect = () => {
    const authStore = useAuthStore();
    authStore.clearUser();
    localStorage.removeItem('auth_token');
    setAllowRequests(false);
    router.push('/login').finally(() => {
        isRefreshing = false;
        isRedirecting = false;
    });
};
// 请求拦截器
axiosInstance.interceptors.request.use((config) => {
    if (!allowRequests) {
        // 如果不允许发送请求，则直接返回一个被拒绝的 Promise
        return Promise.reject(new Error('Operation canceled'));
    }
    const source = axios.CancelToken.source();
    config.cancelToken = source.token;
    pendingRequests.push(source);
    const token = localStorage.getItem('auth_token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    console.log('Request:', {
        method: config.method,
        url: config.url,
        data: config.data,
        params: config.params,
        headers: config.headers,
    });
    return config;
}, (error) => {
    return Promise.reject(error);
});
axiosInstance.interceptors.response.use((response) => {
    // 从 pendingRequests 中移除已完成的请求
    const index = pendingRequests.findIndex((source) => source.token === response.config.cancelToken);
    if (index > -1) {
        pendingRequests.splice(index, 1);
    }
    console.log('base response:', {
        status: response.status,
        statusText: response.statusText,
        data: response.data,
        headers: response.headers,
    });
    const res = response.data;
    if (res.code !== 0) {
        switch (res.code) {
            case 401:
                if (!isRefreshing && !isRedirecting) {
                    isRefreshing = true;
                    isRedirecting = true;
                    Message.error({
                        content: '登录已过期，请重新登录',
                        onClose: clearUserAndRedirect,
                    });
                }
                break;
            case 403:
                if (!isRefreshing && !isRedirecting) {
                    isRefreshing = true;
                    isRedirecting = true;
                    Message.error({
                        content: '没有权限执行此操作',
                        onClose: clearUserAndRedirect,
                    });
                }
                break;
            case 500:
                Message.error('服务器错误，请稍后重试');
                break;
            default:
                Message.error(res.msg || '请求失败，请稍后重试');
        }
        return Promise.reject(new Error(res.msg || 'Error'));
    }
    return res;
}, (error) => {
    if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
        return Promise.reject(error);
    }
    if (error.response) {
        switch (error.response.status) {
            case 401:
                if (!isRefreshing && !isRedirecting) {
                    isRefreshing = true;
                    isRedirecting = true;
                    Message.error({
                        content: '登录已过期，请重新登录',
                        onClose: clearUserAndRedirect,
                    });
                }
                break;
            case 403:
                if (!isRefreshing && !isRedirecting) {
                    isRefreshing = true;
                    isRedirecting = true;
                    Message.error({
                        content: '没有权限执行此操作',
                        onClose: clearUserAndRedirect,
                    });
                }
                break;
        }
    }
    // ... 其他错误处理保持不变
    return Promise.reject(error);
});
export default axiosInstance;
